<template>
  <component>

    <b-card>
      <!-- Landing Page-->
      <b-col
        sm="8"
        md="6"
        lg="12"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <b-card-title class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          {{ greeting2 }}
        </b-card-title>
      </b-col>

      <b-col
        lg="12"
        class="d-none d-lg-flex align-items-center p-5"
        style="margin-top:-10%"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            style="width:60%"
          />

        </div>
      </b-col>
      <!-- /Landing Page-->
    </b-card>

  </component>
</template>

<script>
import {
  BCol, BCardTitle, BImg,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCol,
    BCardTitle,
    BImg,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/coming-soon.svg'),
      greeting: 'welcome',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.sideImg
      }

      this.sideImg = require('@/assets/images/pages/coming-soon.svg')

      return this.sideImg
    },
  },
  setup() {
    const date = new Date()
    const hours = date.getHours()
    const greeting2 = (hours < 12) ? 'Good Morning ! 👋'
      : ((hours <= 18 && hours >= 12) ? 'Good Afternoon ! 👋' : 'Good Night ! 👋')

    console.log(greeting2)

    return {
      greeting2,
    }
  },
  // setup() {
  //     // const now     = new Date()
  //     // const hour    = now.getHours()
  //     const greeting = 'welcome'
  //     //    if(hour >= 12){
  //     //       greeting= "Good Afternoon!"
  //     //     }
  //     //     else {
  //     //         greeting = "Good Morning!"
  //     //     }
  //     // console.log(greeting)

  //   return {
  //     codeVueMultiBasic,
  //     codeVueMultiIcon,
  //     codeMultiPush,
  //     codeVueSelectionLimit,
  //     codeVueDir,
  //     codeVariant,
  //     greeting,
  //   }
  // },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
